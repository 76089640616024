/* Autogenerated file. Do not modify */
'use strict'
const skins = {}
 skins['wysiwyg.viewer.skins.ClipArtSkin'] = {
  "react": [
    [
      "div",
      "outerFrame",
      [],
      {},
      [
        "a",
        "link",
        [],
        {},
        [
          "div",
          "wrp",
          [],
          {},
          [
            "div",
            "img",
            [],
            {}
          ]
        ]
      ]
    ]
  ],
  "exports": {
    "img": {
      "skin": "mobile.core.skins.ImageNewSkin"
    }
  },
  "css": {
    "%wrp": "position:absolute;top:0;right:0;bottom:0;left:0;",
    "%img": "height:100%;"
  }
}
 skins['wysiwyg.viewer.skins.photo.CirclePhoto'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "div",
        "img",
        [],
        {}
      ]
    ]
  ],
  "exports": {
    "img": {
      "skin": "skins.core.ImageNewSkinZoomable"
    }
  },
  "params": {
    "tdr": "URL",
    "shd": "BOX_SHADOW",
    "brw": "BORDER_SIZE",
    "brd": "BORDER_COLOR_ALPHA",
    "contentPaddingLeft": "BORDER_SIZE",
    "contentPaddingRight": "BORDER_SIZE",
    "contentPaddingBottom": "BORDER_SIZE",
    "contentPaddingTop": "BORDER_SIZE"
  },
  "paramsDefaults": {
    "tdr": "BASE_THEME_DIRECTORY",
    "shd": "0 1px 3px rgba(0, 0, 0, 0.5)",
    "brw": "2px",
    "brd": "color_11",
    "contentPaddingLeft": [
      "brw"
    ],
    "contentPaddingRight": [
      "brw"
    ],
    "contentPaddingBottom": [
      "brw"
    ],
    "contentPaddingTop": [
      "brw"
    ]
  },
  "css": {
    "%_zoomedin": "cursor:url([tdr]cursor_zoom_out.png), url([tdr]cursor_zoom_out.cur), auto;overflow:hidden;display:block;",
    "%_zoomedout": "cursor:url([tdr]cursor_zoom_in.png), url([tdr]cursor_zoom_in.cur), auto;",
    "%link": "display:block;[shd]  border-radius:50%;border:[brw] solid [brd];background-color:[brd];overflow:hidden;",
    "%img": "border-radius:50%;overflow:hidden;"
  }
}
 skins['wysiwyg.viewer.skins.photo.DefaultPhoto'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "div",
        "img",
        [],
        {}
      ]
    ]
  ],
  "exports": {
    "contentPaddingLeft": 0,
    "contentPaddingRight": 0,
    "contentPaddingTop": 0,
    "contentPaddingBottom": 0,
    "img": {
      "skin": "skins.core.ImageNewSkinZoomable"
    }
  },
  "params": {
    "tdr": "URL"
  },
  "paramsDefaults": {
    "tdr": "BASE_THEME_DIRECTORY"
  },
  "css": {
    "%_zoomedin": "cursor:url([tdr]cursor_zoom_out.png), url([tdr]cursor_zoom_out.cur), auto;overflow:hidden;display:block;",
    "%_zoomedout": "cursor:url([tdr]cursor_zoom_in.png), url([tdr]cursor_zoom_in.cur), auto;",
    "%link": "display:block;overflow:hidden;",
    "%img": "overflow:hidden;"
  }
}
 skins['wysiwyg.viewer.skins.photo.DoubleBorderCirclePhoto'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "div",
        "img",
        [],
        {}
      ]
    ]
  ],
  "exports": {
    "img": {
      "skin": "skins.core.ImageNewSkinZoomable"
    }
  },
  "params": {
    "bg": "BG_COLOR_ALPHA",
    "sz1": "BORDER_SIZE",
    "brd1": "BORDER_COLOR_ALPHA",
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "sz2": "MARGIN_SIZE",
    "sz4": "MARGIN_SIZE",
    "sz3": "BORDER_SIZE",
    "brd2": "BORDER_COLOR_ALPHA",
    "contentPaddingLeft": "MARGIN_SIZE",
    "contentPaddingRight": "MARGIN_SIZE",
    "contentPaddingBottom": "MARGIN_SIZE",
    "contentPaddingTop": "MARGIN_SIZE"
  },
  "paramsDefaults": {
    "bg": "color_11",
    "sz1": "4px",
    "brd1": "color_14",
    "rd": "50%",
    "shd": "0 1px 3px rgba(0, 0, 0, 0.5)",
    "sz2": "6px",
    "sz4": "10px",
    "sz3": "8px",
    "brd2": "color_14",
    "contentPaddingLeft": [
      "sz2"
    ],
    "contentPaddingRight": [
      "sz2"
    ],
    "contentPaddingBottom": [
      "sz2"
    ],
    "contentPaddingTop": [
      "sz2"
    ]
  },
  "css": {
    "%": "background-color:[bg];border:[sz1] solid [brd1];box-sizing:border-box !important;[rd]  [shd]",
    "%link": "box-sizing:border-box !important;position:relative;margin:[sz2];display:block;[rd]  overflow:hidden;height:100%;",
    "%:after": "position:absolute;content:\"\";top:[sz4];bottom:[sz4];left:[sz4];right:[sz4];border:[sz3] solid [brd2];[rd]",
    "%img": "box-sizing:border-box !important;overflow:hidden;"
  }
}
 skins['wysiwyg.viewer.skins.photo.GlowLinePhoto'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "div",
        "img",
        [],
        {}
      ],
      [
        "div",
        null,
        [
          "_imgGlow"
        ],
        {}
      ]
    ]
  ],
  "exports": {
    "img": {
      "skin": "skins.core.ImageNewSkinZoomable"
    }
  },
  "params": {
    "tdr": "URL",
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "contentPaddingLeft": "SIZE",
    "contentPaddingRight": "SIZE",
    "contentPaddingTop": "SIZE",
    "contentPaddingBottom": "SIZE"
  },
  "paramsDefaults": {
    "tdr": "BASE_THEME_DIRECTORY",
    "rd": "2px",
    "shd": "0 2px 5px rgba(0, 0, 0, 0.53)",
    "contentPaddingLeft": "0",
    "contentPaddingRight": "0",
    "contentPaddingTop": "0",
    "contentPaddingBottom": "0"
  },
  "css": {
    "%_zoomedin": "cursor:url([tdr]cursor_zoom_out.png), url([tdr]cursor_zoom_out.cur), auto;overflow:hidden;display:block;",
    "%_zoomedout": "cursor:url([tdr]cursor_zoom_in.png), url([tdr]cursor_zoom_in.cur), auto;",
    "%link": "display:block;[rd]  [shd]  overflow:hidden;height:100%;position:relative;",
    "%_imgGlow": "box-shadow:rgba(255, 255, 255, 0.59) 0 0 6px 0 inset, rgba(255, 255, 255, 0.92) 0 1px 0 0 inset, rgba(255, 255, 255, 0.2) 0 0 5px 0 inset;[rd]  position:absolute;top:0;right:0;bottom:0;left:0;pointer-events:none;",
    "%img": "[rd]  overflow:hidden;"
  }
}
 skins['wysiwyg.viewer.skins.photo.DoubleBorderPhoto'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "div",
        null,
        [
          "_wrap"
        ],
        {},
        [
          "div",
          "img",
          [],
          {}
        ]
      ],
      [
        "b",
        null,
        [
          "_one"
        ],
        {}
      ],
      [
        "b",
        null,
        [
          "_two"
        ],
        {}
      ],
      [
        "b",
        null,
        [
          "_three"
        ],
        {}
      ]
    ]
  ],
  "exports": {
    "img": {
      "skin": "skins.core.ImageNewSkinZoomable"
    }
  },
  "params": {
    "tdr": "URL",
    "bg": "BG_COLOR_ALPHA",
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "mrg1": "MARGIN_SIZE",
    "brd1": "BORDER_COLOR_ALPHA",
    "sz1": "BORDER_SIZE",
    "brd2": "BORDER_COLOR_ALPHA",
    "sz2": "BORDER_SIZE",
    "mrg2": "MARGIN_SIZE",
    "contentPaddingLeft": "MARGIN_SIZE",
    "contentPaddingRight": "MARGIN_SIZE",
    "contentPaddingBottom": "MARGIN_SIZE",
    "contentPaddingTop": "MARGIN_SIZE"
  },
  "paramsDefaults": {
    "tdr": "BASE_THEME_DIRECTORY",
    "bg": "color_11",
    "rd": "0",
    "shd": "0 1px 3px rgba(0, 0, 0, 0.5)",
    "mrg1": "7px",
    "brd1": "color_15",
    "sz1": "3px",
    "brd2": "color_14",
    "sz2": "1px",
    "mrg2": "5px",
    "contentPaddingLeft": [
      "mrg1"
    ],
    "contentPaddingRight": [
      "mrg1"
    ],
    "contentPaddingBottom": [
      "mrg1"
    ],
    "contentPaddingTop": [
      "mrg1"
    ]
  },
  "css": {
    "%_zoomedin": "cursor:url([tdr]cursor_zoom_out.png), url([tdr]cursor_zoom_out.cur), auto;overflow:hidden;display:block;",
    "%_zoomedout": "cursor:url([tdr]cursor_zoom_in.png), url([tdr]cursor_zoom_in.cur), auto;",
    "%": "background-color:[bg];[rd]  [shd]",
    "%inlineContent": "position:absolute;top:0;right:0;bottom:0;left:0;",
    "%_wrap": "[rd]  box-sizing:border-box !important;display:block;overflow:hidden;position:absolute;top:[mrg1];bottom:[mrg1];left:[mrg1];right:[mrg1];",
    "%img": "[rd]  box-sizing:border-box !important;display:block;position:absolute;overflow:hidden;",
    "% b": "display:block;position:absolute;top:0;right:0;bottom:0;left:0;border-style:solid;[rd]  pointer-events:none;",
    "%_one": "border-color:[brd1];border-width:[sz1];",
    "%_two": "border-color:[brd2];border-width:[sz2];margin:[mrg2];",
    "%_three": "[rd]  box-sizing:border-box !important;display:block;overflow:hidden;position:absolute;top:0;right:0;bottom:0;left:0;"
  }
}
 skins['wysiwyg.viewer.skins.photo.IronPhoto'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "div",
        "img",
        [],
        {}
      ],
      [
        "div",
        null,
        [
          "_imgGlow"
        ],
        {}
      ],
      [
        "div",
        null,
        [
          "_screwTL",
          "_screw"
        ],
        {}
      ],
      [
        "div",
        null,
        [
          "_screwTR",
          "_screw"
        ],
        {}
      ],
      [
        "div",
        null,
        [
          "_screwBL",
          "_screw"
        ],
        {}
      ],
      [
        "div",
        null,
        [
          "_screwBR",
          "_screw"
        ],
        {}
      ]
    ]
  ],
  "exports": {
    "img": {
      "skin": "skins.core.ImageNewSkinZoomable"
    }
  },
  "params": {
    "tdr": "URL",
    "rd": "BORDER_RADIUS",
    "bg": "BG_COLOR_ALPHA",
    "shd": "BOX_SHADOW",
    "contentPaddingLeft": "SIZE",
    "contentPaddingRight": "SIZE",
    "contentPaddingBottom": "SIZE",
    "contentPaddingTop": "SIZE"
  },
  "paramsDefaults": {
    "tdr": "BASE_THEME_DIRECTORY",
    "rd": "2px",
    "bg": "color_11",
    "shd": "0 2px 5px rgba(0, 0, 0, 0.53)",
    "contentPaddingLeft": "12px",
    "contentPaddingRight": "12px",
    "contentPaddingBottom": "12px",
    "contentPaddingTop": "12px"
  },
  "css": {
    "%_zoomedin": "cursor:url([tdr]cursor_zoom_out.png), url([tdr]cursor_zoom_out.cur), auto;overflow:hidden;display:block;",
    "%_zoomedout": "cursor:url([tdr]cursor_zoom_in.png), url([tdr]cursor_zoom_in.cur), auto;",
    "%link": "display:block;[rd]  background-color:[bg];[shd]  overflow:hidden;height:100% !important;width:100% !important;background-image:url([tdr]ironpatern.png);position:relative;",
    "%_imgGlow": "box-shadow:rgba(255, 255, 255, 0.59) 0 0 6px 0 inset, rgba(255, 255, 255, 0.92) 0 1px 0 0 inset, rgba(255, 255, 255, 0.2) 0 0 5px 0 inset;[rd]  position:absolute;top:0;right:0;bottom:0;left:0;pointer-events:none;",
    "%img": "[rd]  overflow:hidden;overflow:hidden;margin:12px;",
    "%_screw": "display:inline-block;background-image:url([tdr]skrew.png);background-repeat:no-repeat;width:15px;height:15px;pointer-events:none;",
    "%_screwTL": "position:absolute;top:5px;left:5px;",
    "%_screwTR": "position:absolute;top:5px;right:5px;",
    "%_screwBL": "position:absolute;bottom:5px;left:5px;",
    "%_screwBR": "position:absolute;bottom:5px;right:5px;"
  }
}
 skins['wysiwyg.viewer.skins.photo.LiftedShadowPhoto'] = {
  "react": [
    [
      "div",
      null,
      [
        "_left",
        "_shd"
      ],
      {}
    ],
    [
      "div",
      null,
      [
        "_right",
        "_shd"
      ],
      {}
    ],
    [
      "a",
      "link",
      [],
      {},
      [
        "div",
        "img",
        [],
        {}
      ]
    ]
  ],
  "exports": {
    "img": {
      "skin": "skins.core.ImageNewSkinZoomable"
    }
  },
  "params": {
    "tdr": "URL",
    "rd": "BORDER_RADIUS",
    "brd": "BORDER_COLOR_ALPHA",
    "brw": "BORDER_SIZE",
    "sizeRd": "SIZE",
    "sizeBrw": "SIZE",
    "contentPaddingLeft": "BORDER_SIZE",
    "contentPaddingRight": "BORDER_SIZE",
    "contentPaddingBottom": "BORDER_SIZE",
    "contentPaddingTop": "BORDER_SIZE"
  },
  "paramsDefaults": {
    "tdr": "BASE_THEME_DIRECTORY",
    "rd": "3px",
    "brd": "color_15",
    "brw": "2px",
    "sizeRd": [
      "rd"
    ],
    "sizeBrw": [
      "brw"
    ],
    "contentPaddingLeft": [
      "brw"
    ],
    "contentPaddingRight": [
      "brw"
    ],
    "contentPaddingBottom": [
      "brw"
    ],
    "contentPaddingTop": [
      "brw"
    ]
  },
  "css": {
    "%_zoomedin": "cursor:url([tdr]cursor_zoom_out.png), url([tdr]cursor_zoom_out.cur), auto;overflow:hidden;display:block;",
    "%_zoomedout": "cursor:url([tdr]cursor_zoom_in.png), url([tdr]cursor_zoom_in.cur), auto;",
    "%link": "display:block;[rd]  box-shadow:0 0 3px rgba(0, 0, 0, 0.5);background-color:[brd];border:[brw] solid [brd];overflow:hidden;",
    "%img": "overflow:hidden;border-radius:calc([sizeRd] - [sizeBrw]);",
    "%_shd": "position:absolute;bottom:-26px;width:165px;height:26px;background-image:url([tdr]liftedshadow_medium.png);background-repeat:no-repeat;",
    "%_left": "left:-20px;background-position:0 0;",
    "%_right": "right:-20px;background-position:100% 0;"
  }
}
 skins['wysiwyg.viewer.skins.photo.LiftedTopPhoto'] = {
  "react": [
    [
      "div",
      null,
      [
        "_left",
        "_shd"
      ],
      {}
    ],
    [
      "div",
      null,
      [
        "_right",
        "_shd"
      ],
      {}
    ],
    [
      "a",
      "link",
      [],
      {},
      [
        "div",
        "img",
        [],
        {}
      ]
    ]
  ],
  "exports": {
    "img": {
      "skin": "skins.core.ImageNewSkinZoomable"
    }
  },
  "params": {
    "tdr": "URL",
    "rd": "BORDER_RADIUS",
    "brd": "BORDER_COLOR_ALPHA",
    "brw": "BORDER_SIZE",
    "sizeRd": "SIZE",
    "sizeBrw": "SIZE",
    "contentPaddingLeft": "BORDER_SIZE",
    "contentPaddingRight": "BORDER_SIZE",
    "contentPaddingBottom": "BORDER_SIZE",
    "contentPaddingTop": "BORDER_SIZE"
  },
  "paramsDefaults": {
    "tdr": "BASE_THEME_DIRECTORY",
    "rd": "3px",
    "brd": "color_15",
    "brw": "2px",
    "sizeRd": [
      "rd"
    ],
    "sizeBrw": [
      "brw"
    ],
    "contentPaddingLeft": [
      "brw"
    ],
    "contentPaddingRight": [
      "brw"
    ],
    "contentPaddingBottom": [
      "brw"
    ],
    "contentPaddingTop": [
      "brw"
    ]
  },
  "css": {
    "%_zoomedin": "cursor:url([tdr]cursor_zoom_out.png), url([tdr]cursor_zoom_out.cur), auto;overflow:hidden;display:block;",
    "%_zoomedout": "cursor:url([tdr]cursor_zoom_in.png), url([tdr]cursor_zoom_in.cur), auto;",
    "%link": "display:block;[rd]  box-shadow:0 0 5px rgba(0, 0, 0, 0.1);background-color:[brd];border:[brw] solid [brd];overflow:hidden;",
    "%img": "overflow:hidden;border-radius:calc([sizeRd] - [sizeBrw]);",
    "%link:after": "content:\"\";position:absolute;height:2px;left:0;right:0;bottom:-2px;background:url([tdr]lifted_top_shades.png) center top no-repeat;",
    "%_shd": "position:absolute;bottom:4%;top:0;width:24px;background-image:url([tdr]lifted_top_shades.png);background-repeat:no-repeat;",
    "%_left": "left:-24px;background-position:100% 100%;",
    "%_right": "right:-24px;background-position:0 100%;"
  }
}
 skins['wysiwyg.viewer.skins.photo.MouseOverPhoto'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "div",
        "img",
        [],
        {}
      ],
      [
        "div",
        "rollover",
        [],
        {}
      ]
    ]
  ],
  "exports": {
    "img": {
      "skin": "skins.core.ImageNewSkinZoomable"
    }
  },
  "params": {
    "tdr": "URL",
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "brd": "BORDER_COLOR_ALPHA",
    "brw": "BORDER_SIZE",
    "sizeRd": "SIZE",
    "sizeBrw": "SIZE",
    "trans": "TRANSITION",
    "bgh": "BG_COLOR_ALPHA",
    "contentPaddingLeft": "BORDER_SIZE",
    "contentPaddingRight": "BORDER_SIZE",
    "contentPaddingBottom": "BORDER_SIZE",
    "contentPaddingTop": "BORDER_SIZE"
  },
  "paramsDefaults": {
    "tdr": "BASE_THEME_DIRECTORY",
    "rd": "5px",
    "shd": "0 1px 3px rgba(0, 0, 0, 0.5)",
    "brd": "color_11",
    "brw": "2px",
    "sizeRd": [
      "rd"
    ],
    "sizeBrw": [
      "brw"
    ],
    "trans": "opacity 0.4s ease 0s",
    "bgh": "color_15",
    "contentPaddingLeft": [
      "brw"
    ],
    "contentPaddingRight": [
      "brw"
    ],
    "contentPaddingBottom": [
      "brw"
    ],
    "contentPaddingTop": [
      "brw"
    ]
  },
  "css": {
    "%_zoomedin": "cursor:url([tdr]cursor_zoom_out.png), url([tdr]cursor_zoom_out.cur), auto;overflow:hidden;display:block;",
    "%_zoomedout": "cursor:url([tdr]cursor_zoom_in.png), url([tdr]cursor_zoom_in.cur), auto;",
    "%link": "[rd]  [shd]  display:block;background-color:[brd];border:[brw] solid [brd];overflow:hidden;height:100%;",
    "%img": "overflow:hidden;border-radius:calc([sizeRd] - [sizeBrw]);",
    "%rollover": "[rd]  [trans]  background-color:[bgh];position:absolute;top:0;left:0;width:100%;height:100%;display:block;opacity:0;pointer-events:none;",
    "%:hover %rollover": "opacity:1;"
  }
}
 skins['wysiwyg.viewer.skins.photo.NewPolaroidPhoto'] = {
  "react": [
    [
      "div",
      null,
      [
        "_shadowImgTL",
        "_bgShadow"
      ],
      {}
    ],
    [
      "div",
      null,
      [
        "_shadowImgTR",
        "_bgShadow"
      ],
      {}
    ],
    [
      "div",
      null,
      [
        "_shadowImgBL",
        "_bgShadow"
      ],
      {}
    ],
    [
      "div",
      null,
      [
        "_shadowImgBR",
        "_bgShadow"
      ],
      {}
    ],
    [
      "a",
      "link",
      [],
      {},
      [
        "div",
        "img",
        [],
        {}
      ]
    ]
  ],
  "exports": {
    "contentPaddingBottom": "50px",
    "img": {
      "skin": "skins.core.ImageNewSkinZoomable"
    }
  },
  "params": {
    "tdr": "URL",
    "shd": "BOX_SHADOW",
    "bg": "BG_COLOR_ALPHA",
    "brw": "BORDER_SIZE",
    "contentPaddingLeft": "BORDER_SIZE",
    "contentPaddingRight": "BORDER_SIZE",
    "contentPaddingTop": "BORDER_SIZE",
    "contentPaddingBottom": "BORDER_SIZE"
  },
  "paramsDefaults": {
    "tdr": "BASE_THEME_DIRECTORY",
    "shd": "0 1px 2px 1px rgba(0, 0, 0, 0.1)",
    "bg": "color_11",
    "brw": "10px",
    "contentPaddingLeft": [
      "brw"
    ],
    "contentPaddingRight": [
      "brw"
    ],
    "contentPaddingTop": [
      "brw"
    ],
    "contentPaddingBottom": [
      "brw"
    ]
  },
  "css": {
    "%_zoomedin": "cursor:url([tdr]cursor_zoom_out.png), url([tdr]cursor_zoom_out.cur), auto;overflow:hidden;display:block;",
    "%_zoomedout": "cursor:url([tdr]cursor_zoom_in.png), url([tdr]cursor_zoom_in.cur), auto;",
    "%link": "display:block;position:absolute;top:0;right:0;bottom:0;left:0;[shd]  background-color:[bg];border:[brw] solid transparent;overflow:hidden;padding:0 0 50px;",
    "%_bgShadow": "background:url([tdr]shadow_photo.png) no-repeat;width:168px;height:154px;opacity:0.8;",
    "%img": "overflow:hidden;",
    "%_shadowImgTL,%_shadowImgTR,%_shadowImgBL,%_shadowImgBR": "position:absolute;",
    "%_shadowImgTL": "background-position:0 0;left:-13px;top:-15px;",
    "%_shadowImgTR": "background-position:100% 0;right:-15px;top:-14px;",
    "%_shadowImgBL": "background-position:0 100%;left:-14px;bottom:-13px;",
    "%_shadowImgBR": "background-position:100% 100%;right:-16px;bottom:-14px;"
  }
}
 skins['wysiwyg.viewer.skins.photo.NoSkinPhoto'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "div",
        "img",
        [],
        {}
      ]
    ]
  ],
  "exports": {
    "img": {
      "skin": "skins.core.ImageNewSkinZoomable"
    }
  },
  "params": {
    "tdr": "URL",
    "contentPaddingLeft": "SIZE",
    "contentPaddingRight": "SIZE",
    "contentPaddingTop": "SIZE",
    "contentPaddingBottom": "SIZE"
  },
  "paramsDefaults": {
    "tdr": "BASE_THEME_DIRECTORY",
    "contentPaddingLeft": "0",
    "contentPaddingRight": "0",
    "contentPaddingTop": "0",
    "contentPaddingBottom": "0"
  },
  "css": {
    "%_zoomedin": "cursor:url([tdr]cursor_zoom_out.png), url([tdr]cursor_zoom_out.cur), auto;overflow:hidden;display:block;",
    "%_zoomedout": "cursor:url([tdr]cursor_zoom_in.png), url([tdr]cursor_zoom_in.cur), auto;",
    "%link": "display:block;overflow:hidden;",
    "%img": "overflow:hidden;"
  }
}
 skins['wysiwyg.viewer.skins.photo.PaperclipPhoto'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "div",
        "outerFrame",
        [],
        {},
        [
          "div",
          "wrp",
          [],
          {},
          [
            "div",
            "img",
            [],
            {}
          ]
        ]
      ],
      [
        "div",
        null,
        [
          "_xxx"
        ],
        {}
      ]
    ],
    [
      "div",
      "clip",
      [],
      {}
    ]
  ],
  "exports": {
    "img": {
      "skin": "skins.core.ImageNewSkin"
    }
  },
  "params": {
    "shd": "BOX_SHADOW",
    "bg": "BG_COLOR_ALPHA",
    "tdr": "URL"
  },
  "paramsDefaults": {
    "shd": "0 1px 3px rgba(0, 0, 0, 0.5)",
    "bg": "color_11",
    "tdr": "BASE_THEME_DIRECTORY"
  },
  "css": {
    "%link": "display:block;position:absolute;top:0;right:0;bottom:0;left:0;",
    "%wrp": "position:absolute;top:0;right:0;bottom:0;left:0;padding:10px;[shd]  background-color:[bg];",
    "%img": "position:absolute;width:100%;height:100%;",
    "%_xxx": "position:absolute;top:0;right:0;bottom:0;left:0;background:url([tdr]net.png) center center repeat;",
    "%clip": "position:absolute;top:-65px;right:50%;margin-right:-32px;width:65px;height:87px;background-image:url([tdr]icon_clip.png);"
  }
}
 skins['wysiwyg.viewer.skins.photo.PolaroidPhoto'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "div",
        "img",
        [],
        {}
      ]
    ]
  ],
  "exports": {
    "contentPaddingBottom": "30px",
    "img": {
      "skin": "skins.core.ImageNewSkinZoomable"
    }
  },
  "params": {
    "tdr": "URL",
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "brd": "BORDER_COLOR_ALPHA",
    "brw": "BORDER_SIZE",
    "sizeRd": "SIZE",
    "sizeBrw": "SIZE",
    "contentPaddingLeft": "BORDER_SIZE",
    "contentPaddingRight": "BORDER_SIZE",
    "contentPaddingTop": "BORDER_SIZE",
    "contentPaddingBottom": "BORDER_SIZE"
  },
  "paramsDefaults": {
    "tdr": "BASE_THEME_DIRECTORY",
    "rd": "5px",
    "shd": "0 1px 3px rgba(0, 0, 0, 0.5)",
    "brd": "color_15",
    "brw": "2px",
    "sizeRd": [
      "rd"
    ],
    "sizeBrw": [
      "brw"
    ],
    "contentPaddingLeft": [
      "brw"
    ],
    "contentPaddingRight": [
      "brw"
    ],
    "contentPaddingTop": [
      "brw"
    ],
    "contentPaddingBottom": [
      "brw"
    ]
  },
  "css": {
    "%_zoomedin": "cursor:url([tdr]cursor_zoom_out.png), url([tdr]cursor_zoom_out.cur), auto;overflow:hidden;display:block;",
    "%_zoomedout": "cursor:url([tdr]cursor_zoom_in.png), url([tdr]cursor_zoom_in.cur), auto;",
    "%link": "display:block;[rd]  [shd]  background-color:[brd];border:[brw] solid [brd];overflow:hidden;padding:0 0 30px 0;",
    "%img": "overflow:hidden;border-radius:calc([sizeRd] - [sizeBrw]);"
  }
}
 skins['wysiwyg.viewer.skins.photo.RoundPhoto'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "div",
        "img",
        [],
        {}
      ]
    ]
  ],
  "exports": {
    "img": {
      "skin": "skins.core.ImageNewSkinZoomable"
    }
  },
  "params": {
    "tdr": "URL",
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "brd": "BORDER_COLOR_ALPHA",
    "brw": "BORDER_SIZE",
    "sizeRd": "SIZE",
    "sizeBrw": "SIZE",
    "contentPaddingLeft": "BORDER_SIZE",
    "contentPaddingRight": "BORDER_SIZE",
    "contentPaddingBottom": "BORDER_SIZE",
    "contentPaddingTop": "BORDER_SIZE"
  },
  "paramsDefaults": {
    "tdr": "BASE_THEME_DIRECTORY",
    "rd": "5px",
    "shd": "0 1px 3px rgba(0, 0, 0, 0.5)",
    "brd": "color_11",
    "brw": "2px",
    "sizeRd": [
      "rd"
    ],
    "sizeBrw": [
      "brw"
    ],
    "contentPaddingLeft": [
      "brw"
    ],
    "contentPaddingRight": [
      "brw"
    ],
    "contentPaddingBottom": [
      "brw"
    ],
    "contentPaddingTop": [
      "brw"
    ]
  },
  "css": {
    "%_zoomedin": "cursor:url([tdr]cursor_zoom_out.png), url([tdr]cursor_zoom_out.cur), auto;overflow:hidden;display:block;",
    "%_zoomedout": "cursor:url([tdr]cursor_zoom_in.png), url([tdr]cursor_zoom_in.cur), auto;",
    "%link": "display:block;[rd]  [shd]  background-color:[brd];border:[brw] solid [brd];overflow:hidden;height:100%;",
    "%img": "overflow:hidden;border-radius:calc([sizeRd] - [sizeBrw]);"
  }
}
 skins['wysiwyg.viewer.skins.photo.RoundShadowPhoto'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "div",
        "img",
        [],
        {}
      ]
    ]
  ],
  "exports": {
    "img": {
      "skin": "skins.core.ImageNewSkinZoomable"
    }
  },
  "params": {
    "tdr": "URL",
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "brd": "BORDER_COLOR_ALPHA",
    "brw": "BORDER_SIZE",
    "contentPaddingLeft": "BORDER_SIZE",
    "contentPaddingRight": "BORDER_SIZE",
    "contentPaddingBottom": "BORDER_SIZE",
    "contentPaddingTop": "BORDER_SIZE"
  },
  "paramsDefaults": {
    "tdr": "BASE_THEME_DIRECTORY",
    "rd": "5px",
    "shd": "0 1px 3px rgba(0, 0, 0, 0.5)",
    "brd": "color_15",
    "brw": "2px",
    "contentPaddingLeft": [
      "brw"
    ],
    "contentPaddingRight": [
      "brw"
    ],
    "contentPaddingBottom": [
      "brw"
    ],
    "contentPaddingTop": [
      "brw"
    ]
  },
  "css": {
    "%": "background:url([tdr]net.png) center center repeat;",
    "%link": "display:block;[rd]  [shd]  background-color:[brd];border:[brw] solid [brd];overflow:hidden;height:100%;",
    "%img": "[rd]  overflow:hidden;"
  }
}
 skins['wysiwyg.viewer.skins.photo.ScotchDoubleHorizontal'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "div",
        "img",
        [],
        {}
      ]
    ],
    [
      "div",
      null,
      [
        "_ScotchT"
      ],
      {}
    ],
    [
      "div",
      null,
      [
        "_ScotchB"
      ],
      {}
    ]
  ],
  "exports": {
    "img": {
      "skin": "skins.core.ImageNewSkinZoomable"
    }
  },
  "params": {
    "tdr": "URL",
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "brw": "BORDER_SIZE",
    "brd": "BORDER_COLOR_ALPHA",
    "sizeRd": "SIZE",
    "sizeBrw": "SIZE",
    "contentPaddingLeft": "BORDER_SIZE",
    "contentPaddingRight": "BORDER_SIZE",
    "contentPaddingBottom": "BORDER_SIZE",
    "contentPaddingTop": "BORDER_SIZE"
  },
  "paramsDefaults": {
    "tdr": "BASE_THEME_DIRECTORY",
    "rd": "0",
    "shd": "0 2px 5px rgba(0, 0, 0, 0.15)",
    "brw": "10px",
    "brd": "color_11",
    "sizeRd": [
      "rd"
    ],
    "sizeBrw": [
      "brw"
    ],
    "contentPaddingLeft": [
      "brw"
    ],
    "contentPaddingRight": [
      "brw"
    ],
    "contentPaddingBottom": [
      "brw"
    ],
    "contentPaddingTop": [
      "brw"
    ]
  },
  "css": {
    "%_zoomedin": "cursor:url([tdr]cursor_zoom_out.png), url([tdr]cursor_zoom_out.cur), auto;overflow:hidden;display:block;",
    "%_zoomedout": "cursor:url([tdr]cursor_zoom_in.png), url([tdr]cursor_zoom_in.cur), auto;",
    "%link": "display:block;[rd]  [shd]  overflow:hidden;height:100%;border:[brw] solid [brd];position:relative;",
    "%_imgGlow": "box-shadow:inset 0 2px 0 rgba(255, 255, 255, 0.21), inset 0 1px 4px 1px rgba(255, 255, 255, 0.2);[rd]  position:absolute;top:0;right:0;bottom:0;left:0;",
    "%img": "overflow:hidden;border-radius:calc([sizeRd] - [sizeBrw]);",
    "%_ScotchT": "position:absolute;display:inline-block;background-image:url([tdr]scotcht.png);background-repeat:no-repeat;width:78px;height:40px;top:-20px;left:50%;margin-left:-39px;",
    "%_ScotchB": "position:absolute;display:inline-block;background-image:url([tdr]scotchb.png);background-repeat:no-repeat;width:100px;height:48px;bottom:-20px;right:50%;margin-right:-50px;"
  }
}
 skins['wysiwyg.viewer.skins.photo.ScotchDoubleVertical'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "div",
        "img",
        [],
        {}
      ]
    ],
    [
      "div",
      null,
      [
        "_ScotchL"
      ],
      {}
    ],
    [
      "div",
      null,
      [
        "_ScotchR"
      ],
      {}
    ]
  ],
  "exports": {
    "img": {
      "skin": "skins.core.ImageNewSkinZoomable"
    }
  },
  "params": {
    "tdr": "URL",
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "brw": "BORDER_SIZE",
    "brd": "BORDER_COLOR_ALPHA",
    "sizeRd": "SIZE",
    "sizeBrw": "SIZE",
    "contentPaddingLeft": "BORDER_SIZE",
    "contentPaddingRight": "BORDER_SIZE",
    "contentPaddingBottom": "BORDER_SIZE",
    "contentPaddingTop": "BORDER_SIZE"
  },
  "paramsDefaults": {
    "tdr": "BASE_THEME_DIRECTORY",
    "rd": "0",
    "shd": "0 2px 5px rgba(0, 0, 0, 0.15)",
    "brw": "10px",
    "brd": "color_11",
    "sizeRd": [
      "rd"
    ],
    "sizeBrw": [
      "brw"
    ],
    "contentPaddingLeft": [
      "brw"
    ],
    "contentPaddingRight": [
      "brw"
    ],
    "contentPaddingBottom": [
      "brw"
    ],
    "contentPaddingTop": [
      "brw"
    ]
  },
  "css": {
    "%_zoomedin": "cursor:url([tdr]cursor_zoom_out.png), url([tdr]cursor_zoom_out.cur), auto;overflow:hidden;display:block;",
    "%_zoomedout": "cursor:url([tdr]cursor_zoom_in.png), url([tdr]cursor_zoom_in.cur), auto;",
    "%link": "display:block;[rd]  [shd]  overflow:hidden;height:100%;border:[brw] solid [brd];position:relative;",
    "%_imgGlow": "box-shadow:inset 0 2px 0 rgba(255, 255, 255, 0.21), inset 0 1px 4px 1px rgba(255, 255, 255, 0.2);[rd]  position:absolute;top:0;right:0;bottom:0;left:0;",
    "%img": "overflow:hidden;border-radius:calc([sizeRd] - [sizeBrw]);",
    "%_ScotchL": "position:absolute;display:inline-block;background-image:url([tdr]scotchl.png);background-repeat:no-repeat;width:85px;height:86px;top:-20px;left:-30px;",
    "%_ScotchR": "position:absolute;display:inline-block;background-image:url([tdr]scotchr.png);background-repeat:no-repeat;width:85px;height:86px;top:-20px;right:-30px;"
  }
}
 skins['wysiwyg.viewer.skins.photo.ScotchTapePhoto'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "div",
        "outerFrame",
        [],
        {},
        [
          "div",
          "wrp",
          [],
          {},
          [
            "div",
            "img",
            [],
            {}
          ]
        ],
        [
          "div",
          "left",
          [],
          {}
        ],
        [
          "div",
          "right",
          [],
          {}
        ]
      ],
      [
        "div",
        null,
        [
          "_xxx"
        ],
        {}
      ]
    ]
  ],
  "exports": {
    "img": {
      "skin": "skins.core.ImageNewSkin"
    }
  },
  "params": {
    "shd": "BOX_SHADOW",
    "bg": "BG_COLOR_ALPHA",
    "tdr": "URL"
  },
  "paramsDefaults": {
    "shd": "0 1px 3px rgba(0, 0, 0, 0.5)",
    "bg": "color_11",
    "tdr": "BASE_THEME_DIRECTORY"
  },
  "css": {
    "%link": "display:block;position:absolute;top:0;right:0;bottom:0;left:0;",
    "%wrp": "position:absolute;top:0;right:0;bottom:0;left:0;padding:10px;[shd]  background-color:[bg];",
    "%_xxx": "position:absolute;top:0;right:0;bottom:0;left:0;background:url([tdr]net.png) center center repeat;",
    "%left": "position:absolute;top:-20px;left:-30px;width:120px;height:86px;background-image:url([tdr]scotch_tape.png);background-repeat:no-repeat;background-position:0 0;",
    "%right": "position:absolute;top:-20px;right:-30px;width:120px;height:88px;background-image:url([tdr]scotch_tape.png);background-repeat:no-repeat;background-position:100% 100%;"
  }
}
 skins['wysiwyg.viewer.skins.photo.ScotchTopPhoto'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "div",
        "img",
        [],
        {}
      ]
    ],
    [
      "div",
      null,
      [
        "_Scotch"
      ],
      {}
    ]
  ],
  "exports": {
    "img": {
      "skin": "skins.core.ImageNewSkinZoomable"
    }
  },
  "params": {
    "tdr": "URL",
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "brw": "BORDER_SIZE",
    "brd": "BORDER_COLOR_ALPHA",
    "sizeRd": "SIZE",
    "sizeBrw": "SIZE",
    "contentPaddingLeft": "BORDER_SIZE",
    "contentPaddingRight": "BORDER_SIZE",
    "contentPaddingBottom": "BORDER_SIZE",
    "contentPaddingTop": "BORDER_SIZE"
  },
  "paramsDefaults": {
    "tdr": "BASE_THEME_DIRECTORY",
    "rd": "0",
    "shd": "0 2px 5px rgba(0, 0, 0, 0.15)",
    "brw": "10px",
    "brd": "color_11",
    "sizeRd": [
      "rd"
    ],
    "sizeBrw": [
      "brw"
    ],
    "contentPaddingLeft": [
      "brw"
    ],
    "contentPaddingRight": [
      "brw"
    ],
    "contentPaddingBottom": [
      "brw"
    ],
    "contentPaddingTop": [
      "brw"
    ]
  },
  "css": {
    "%_zoomedin": "cursor:url([tdr]cursor_zoom_out.png), url([tdr]cursor_zoom_out.cur), auto;overflow:hidden;display:block;",
    "%_zoomedout": "cursor:url([tdr]cursor_zoom_in.png), url([tdr]cursor_zoom_in.cur), auto;",
    "%link": "display:block;[rd]  [shd]  overflow:hidden;height:100%;border:[brw] solid [brd];position:relative;",
    "%_imgGlow": "box-shadow:inset 0 2px 0 rgba(255, 255, 255, 0.21), inset 0 1px 4px 1px rgba(255, 255, 255, 0.2);[rd]  position:absolute;top:0;right:0;bottom:0;left:0;",
    "%img": "overflow:hidden;border-radius:calc([sizeRd] - [sizeBrw]);",
    "%_Scotch": "position:absolute;display:inline-block;background-image:url([tdr]scotchvertical.png);background-repeat:no-repeat;width:42px;height:86px;top:-43px;left:50%;margin-left:-21px;"
  }
}
 skins['wysiwyg.viewer.skins.photo.SloppyPhoto'] = {
  "react": [
    [
      "div",
      null,
      [
        "_brd"
      ],
      {}
    ],
    [
      "a",
      "link",
      [],
      {},
      [
        "div",
        "img",
        [],
        {}
      ]
    ]
  ],
  "exports": {
    "img": {
      "skin": "skins.core.ImageNewSkinZoomable"
    }
  },
  "params": {
    "tdr": "URL",
    "contentPaddingLeft": "SIZE",
    "contentPaddingRight": "SIZE",
    "contentPaddingBottom": "SIZE",
    "contentPaddingTop": "SIZE"
  },
  "paramsDefaults": {
    "tdr": "BASE_THEME_DIRECTORY",
    "contentPaddingLeft": "9px",
    "contentPaddingRight": "9px",
    "contentPaddingBottom": "9px",
    "contentPaddingTop": "9px"
  },
  "css": {
    "%_zoomedin": "cursor:url([tdr]cursor_zoom_out.png), url([tdr]cursor_zoom_out.cur), auto;overflow:hidden;display:block;",
    "%_zoomedout": "cursor:url([tdr]cursor_zoom_in.png), url([tdr]cursor_zoom_in.cur), auto;",
    "%link": "width:calc(100% - 5px) !important;height:calc(100% - 5px) !important;position:absolute;background:url([tdr]sloppyframe.png) no-repeat 0 0;display:block;",
    "%_brd": "position:absolute;top:5px;bottom:0;left:5px;right:0;background:url([tdr]sloppyframe.png) no-repeat 100% 100%;",
    "%img": "overflow:hidden;margin:9px;"
  }
}
 skins['wysiwyg.viewer.skins.photo.VintagePhoto'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "div",
        null,
        [
          "_container"
        ],
        {},
        [
          "div",
          "img",
          [],
          {}
        ]
      ],
      [
        "div",
        null,
        [
          "_tl",
          "_corner"
        ],
        {}
      ],
      [
        "div",
        null,
        [
          "_tr",
          "_corner"
        ],
        {}
      ],
      [
        "div",
        null,
        [
          "_bl",
          "_corner"
        ],
        {}
      ],
      [
        "div",
        null,
        [
          "_br",
          "_corner"
        ],
        {}
      ]
    ]
  ],
  "exports": {
    "contentPaddingLeft": "30px",
    "contentPaddingRight": "30px",
    "contentPaddingTop": "30px",
    "contentPaddingBottom": "30px",
    "img": {
      "skin": "skins.core.ImageNewSkinZoomable"
    }
  },
  "params": {
    "tdr": "URL",
    "brw": "BORDER_SIZE",
    "clr": "BORDER_COLOR_ALPHA",
    "pad": "PADDING_SIZE",
    "contentPaddingLeft": "SIZE",
    "contentPaddingRight": "SIZE",
    "contentPaddingTop": "SIZE",
    "contentPaddingBottom": "SIZE"
  },
  "paramsDefaults": {
    "tdr": "BASE_THEME_DIRECTORY",
    "brw": "10px",
    "clr": "color_15",
    "pad": "15px",
    "contentPaddingLeft": [
      "pad",
      "brw"
    ],
    "contentPaddingRight": [
      "pad",
      "brw"
    ],
    "contentPaddingTop": [
      "pad",
      "brw"
    ],
    "contentPaddingBottom": [
      "pad",
      "brw"
    ]
  },
  "css": {
    "%_zoomedin": "cursor:url([tdr]cursor_zoom_out.png), url([tdr]cursor_zoom_out.cur), auto;overflow:hidden;display:block;",
    "%_zoomedout": "cursor:url([tdr]cursor_zoom_in.png), url([tdr]cursor_zoom_in.cur), auto;",
    "%link": "display:block;position:absolute;overflow:hidden;padding:30px;box-sizing:border-box;width:100% !important;height:100% !important;",
    "%_container": "border:[brw] solid [clr];padding:[pad];",
    "%img": "position:relative;overflow:hidden;",
    "%_corner": "width:29px;height:27px;border-color:[clr];border-style:solid;position:absolute;",
    "%_tl": "top:3px;left:1px;border-width:0 [brw] [brw] 0;",
    "%_bl": "bottom:3px;left:1px;border-width:[brw] [brw] 0 0;",
    "%_br": "bottom:3px;right:1px;border-width:[brw] 0 0 [brw];",
    "%_tr": "top:3px;right:1px;border-width:0 0 [brw] [brw];"
  }
}

module.exports = skins